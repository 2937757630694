module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-identity/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://carllundin.netlify.com/"},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
